<template>
    <div class="w-full h-auto">
        <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0">
            <a-spin>
                <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
            </a-spin>
        </div>
        <header v-if="step === 1" class="
        mb-4
        flex flex-col-reverse
        items-center
        justify-center
        lg:flex-row lg:justify-between
        gap-3
      ">
            <div class="w-full md:max-w-sm grid grid-cols-1 lg:grid-cols-2 gap-4">
              <a-button type="primary" shape="round" :block="true" class="w-64" @click="filterDrawer = true">
                    <span class="flex items-start justify-start font-sans text-xs">
                        <a-icon type="sliders" :style="{ fontSize: '16px' }" class="mr-2" />
                        <b>Filtros de búsqueda</b>
                    </span>
              </a-button>
            </div>
            <div class="w-100 d-flex search">

            </div>
            <div class="w-full sm:max-w-sm">
                <label for="table-search" class="sr-only">Search</label>
                <div class="relative">
                    <div class="
                      flex
                      absolute
                      inset-y-0
                      left-0
                      items-center
                      pl-3
                      pointer-events-none
                    ">
                        <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </div>
                    <a-input-search placeholder="Buscar un usuario en particular" class="rounded-full" style="width: 100%"
                        v-model="filterSearch" />
                </div>
            </div>
        </header>
        <div v-if="step === 1"
            class="bg-white overflow-x-auto rounded-xl border border-gray-300 mx-4 mt-4 search-complaints">
            <a-table :columns="columns" :data-source="dataRows" :pagination="false" :scroll="{ x: '1220px' }">
                <div slot="fecha_reporte" class="title-size font-bold text-gray-500 text-center ">
                    <p>Fecha de reporte</p>
                </div>
                <div slot="responsable" class="title-size font-bold text-gray-500">
                    Responsable
                </div>
                <div slot="estado" class="title-size font-bold text-gray-500">
                    Estado de gestión
                </div>
                <div slot="status" slot-scope="text, record" class="flex flex-row">
                    <p class="w-full rounded-full text-center"
                        :class="record.estado_ev == 'Borrador' ? 'text-blue-600 bg-blue-200' : record.estado_ev == 'Por Gestionar' ? 'text-red-600 bg-red-100' : 'text-green-600 bg-green-100'">
                        {{ record.estado_ev }}
                    </p>

                </div>
                <div slot="actions" class="title-size font-bold text-gray-500">
                    Acciones
                </div>
                <div slot="actions" slot-scope="text, record" class="flex w-full flex-row justify-items-center">
                  <a-button :disabled="['Borrador', 'Gestionado'].includes(record.estado_ev)" type="primary" size="small" @click="changeStep(2, undefined, record)">
                    <span class="flex items-start justify-start font-sans text-xs">
                      <a-icon type="sliders" :style="{ fontSize: '16px' }" class="mr-2" />
                      <b>Gestionar</b>
                    </span>
                  </a-button>
                  <a-button :disabled="['Por Gestionar', null, undefined].includes(record.estado_ev)" type="primary" ghost class="ml-2" size="small" @click="changeStep(3, record, record)">
                    <span class="flex items-start justify-start font-sans text-xs">
                      <a-icon type="eye" :style="{ fontSize: '16px' }" />
                    </span>
                  </a-button>
                  <a-button :disabled="['Por Gestionar', 'Gestionado', null, undefined].includes(record.estado_ev)" type="primary" ghost class="ml-2" size="small" @click="changeStep(4, record, record)">
                    <span class="flex items-start justify-start font-sans text-xs">
                      <a-icon type="edit" :style="{ fontSize: '16px' }" />
                    </span>
                  </a-button>
                </div>
            </a-table>
            <div class="p-4 flex justify-end">
                <a-pagination v-model="page" :page-size="pageSize" :page-size-options="pageSizeOptions" :total="count"
                    show-size-changer size="large" />
            </div>
        </div>

      <a-drawer
          title="Filtros de Búsqueda"
          placement="right"
          :visible="filterDrawer"
          @close="filterDrawer = false"
          width="512"
      >
        <a-row>
          <a-col :span="24">
            <BaseSelect
                v-model="filterModel.status"
                label="Estado de Gestión"
                :selectOptions="[{ id: 'Por Gestionar', name: 'Por Gestionar' }, { id: 'Borrador', name: 'Borrador' }, { id: 'Gestionado', name: 'Gestionado' }]"
            />
          </a-col>
          <a-col :span="24">
            <BaseRadio
                v-model="filterModel.typeDate"
                label="Fecha de creación del informe"
                :radioOptions="[{ id: 1, name: 'Una sola fecha' }, { id: 2, name: 'Periodo de Fechas' }]"
                @onChangeValue="onChangeDateRadio"
            />
          </a-col>
          <a-col v-if="filterDateOption === 2" :span="24">
            <BaseRangeDate @notify-dates="onChangeDateRange" />
          </a-col>
          <a-col v-else-if="filterDateOption === 1" :span="24">
            <BaseDate lable="Fecha" v-model="filterModel.filter_date" />
          </a-col>
          <a-col :span="24">
            <div class="w-100 d-flex mt-8 items-center justify-center">
              <a-button block size="large" type="primary" @click="getReports">
                    <span class="flex items-start justify-start font-sans">
                      <a-icon type="sliders" :style="{ fontSize: '16px' }" class="mr-2" />
                      <b>Filtrar</b>
                    </span>
              </a-button>
            </div>
          </a-col>
        </a-row>
      </a-drawer>

        <FloatingButton v-if="step === 2" label="GUARDAR COMO BORRADOR" success="true" @saveDraft="saveDraftBtn" />

        <DynamicForm
            v-if="step === 2"
            :with-confirmation="true"
            @methodSubmit="callApi"
            @saveDraft="saveDraft"
            @saveDraftBtn="saveDraftBtn"
            @methodCancel="changeStep"
            @addAnexo="setFiles"
            :urlform="'annual-report'"
            :isReport="false"
            modalCancelText="GUARDAR COMO BORRADOR"
            :initial-response="selectedItem"
        >
          <template #modalTitle>
            Confirmación de carga de Informe
          </template>
          <template #modalContent>
            <p>
              Por favor tener en cuenta una vez se carga el informe, el informe generado en el formulario
              <b>NO PODRÁ SER MODIFICADO O EDITADO,</b> si aún debe confirmar los datos del informa, se sugiere
              guardar como borrador.
            </p>
            <p>
              ¿Está seguro que desea guardar el informe creado?
            </p>
          </template>
          <template #modalAdditionalButton="{ formData, closeModal }">
            <a-button
                type="danger"
                :block="true"
                shape="round"
                ghost
                @click.native="() => {
                  saveDraft(formData, true)
                  return closeModal && closeModal()
                }"
            >
              GUARDAR COMO BORRADOR
            </a-button>
          </template>
        </DynamicForm>

        <DetailAnualInform v-if="step === 3" @methodCancel="changeStep" :getDetail="getDetail" />
      <DynamicForm v-if="step === 4" :with-confirmation="true" @methodSubmit="callApi" @saveDraft="saveDraft" @saveDraftBtn="saveDraftBtn"
                   @methodCancel="changeStep" @addAnexo="setFiles" :urlform="'annual-report'" :init-form="getDetail" :isReport="false">
        <template #modalTitle>
          Confirmación de carga de Informe
        </template>
        <template #modalContent>
          <p>
            Por favor tener en cuenta una vez se carga el informe, el informe generado en el formulario
            <b>NO PODRÁ SER MODIFICADO O EDITADO,</b> si aún debe confirmar los datos del informa, se sugiere
            guardar como borrador.
          </p>
          <p>
            ¿Está seguro que desea guardar el informe creado?
          </p>
        </template>
        <template #modalAdditionalButton="{ formData }">
          <a-button
              type="danger"
              :block="true"
              shape="round"
              ghost
              @click.native="saveDraft(formData, true)"
          >
            GUARDAR COMO BORRADOR
          </a-button>
        </template>
      </DynamicForm>
    </div>
</template>
  
<script>
// #004884
import moment from "moment";

const columns = [
    {
        slots: { title: "fecha_reporte" },
        dataIndex: "fecha_reporte",
        key: "fecha_reporte",
        width: 80,
    },
    {
        slots: { title: "responsable" },
        dataIndex: "responsable",
        key: "responsable",
        width: 80,
    },
    {
        slots: { title: "estado" },
        dataIndex: "estado",
        key: "estado",
        width: 60,
        ellipsis: true,
        scopedSlots: { customRender: 'status' },
    },
    {
        slots: { title: "actions" },
        dataIndex: "actions",
        key: "actions",
        scopedSlots: { customRender: "actions" },
        width: 60,
    },
];

import DynamicForm from "@/components/DynamicForm/";
import DetailAnualInform from "../../../../components/DetailAnualInform/index.vue"
import FloatingButton from "../../../../components/UI/FloatingButton.vue";
import {get, uniqueId} from "lodash"


export default {
    components: { DynamicForm, FloatingButton, DetailAnualInform },
    data() {
        return {
            getDetail: {},
            step: 1,
            loading: false,
            showTab: false,
            filterDrawer: false,
            filterDateOption: null,
            filterModel: {
              status: null,
              typeDate: null,
              filter_date: null,
              start_date: null,
              end_date: null,
            },
            count: 0,
            filterSearch: "",
            page: 1,
            pageSize: 100,
            pageSizeOptions: ["50", "100", "150", "200", "250"],
            columns: columns,
            dataRows: [],
            selectedItem: {},
            files: []
        };
    },
    created() {
      this.refreshLocal();
      this.getReports();
    },
    watch: {
        page: function (newVal, oldVal) {
            if (newVal == this.$route.query.page) {
                return;
            } else {
                this.updateRouterQuery();
            }
        },
        pageSize: function (newVal, oldVal) {
            if (newVal == this.$route.query.pageSize) {
                return;
            } else {
                this.$router.replace({
                    query: { page: this.page, pageSize: this.pageSize },
                });
            }
        },
        "$route.query.page": function () {
            this.getReports();
        },
      filterModel: {
          handler(val) {
            console.log(val)
          },
          deep: true
      }
    },
    computed: {
      userCompany: function () {
        return 38 // this.$store.state?.session?.user?.id_company?.id
      },
    },
    methods: {
      refreshLocal() {
        localStorage.removeItem("DynamicFormID");
        localStorage.removeItem("DynamicForm");
      },
      getLocal() {
        const jsonLocal = localStorage.getItem("DynamicForm");
        let data = {}
        if (jsonLocal) {
            try {
                const parsedData = JSON.parse(jsonLocal);
                if (typeof parsedData === "object") {
                    data['json'] = parsedData;
                }
            } catch (error) {
                data['json'] = null;
            }
        }
        return data;
      },
      updateRouterQuery() {
        this.$router
            .replace({
              query: {
                page: this.page
              },
            })
            .catch((error) => error);
      },
      async getReports() {
          this.loading = true;
          this.filterDrawer = false
          const params = {
            page: this.page,
            page_size: this.pageSize,
            entity_code: this.userCompany,
          }

        let ignore = ["typeDate"]
        if (this.filterModel.typeDate === 1) {
            ignore = ["start_date", "end_date", "typeDate"]
        }

        if (this.filterModel.typeDate === 2) {
          ignore = ["filter_date", "typeDate"]
        }

          Object.keys(this.filterModel)
              .filter((key) => !ignore.includes(key))
              .forEach((key) => {
                params[key] = this.filterModel[key]
              })

          const paramsString = Object.keys(params)
              .filter((key) => ![null, undefined, ""].includes(params[key]))
              .map((key) => `${key}=${params[key]}`)
              .join("&")
        

          let { error, data } = await this.$api.getAnualReport(`?${paramsString}`);
          // let { error, data } = await this.fakeReports();

          if (error) {
              this.loading = false;
              return;
          }

          this.count = data.count;
          this.dataRows = data.data.map((row) => {
              return {
                  ...row,
                  key: row.document_id,
                  estado_ev: row.estado_ev || 'Por Gestionar',
                  actions: ''
              };
          });

          this.loading = false;
      },
      async getReport(DocId) {
        if (!DocId) {
          return;
        }
          this.loading = true;

        let { error, data } = await this.$api.getAnualReport(`?id=${DocId}`, DocId);

        if (error) {
            this.loading = false;
            return;
        }
          this.getDetail = data[DocId]['ev_form']
          // this.getDetail = data.data

          this.loading = false;

      },
      async changeStep(step = 1, record = "", initial = {}) {
        this.selectedItem = initial
          // if (record != "") {
          //     await this.getReport(record.key);
          // }
          if (record !== "") {
            console.log(record)
              await this.getReport(record.key);
          }
          this.step = step;
      },
      async callApi(json) {
        this.loading = true;
        const payload = {
          ...json,
          steps: {
            0: {
              ...json.steps['0'],
              complete: Object.keys(json.steps['0']).length > 8
            },
            1: {
              ...json.steps['1'],
            }
          },
          ...this.selectedItem,
          estado_ev: 'Borrador',
          document_id: this.selectedItem?.key
        }
        const { data } = await this.$api.createEVAnualReport(payload);
        // const { data } = await this.fakePost(json)
          localStorage.setItem(
               "DynamicFormID",
              get(data, "data.document_id")
          );

          this.loading = false;
      },
      async saveDraft(json, borradorBtn = false) {
        const payload = {
          ...json,
          steps: {
            0: {
              ...json.steps['0'],
              complete: Object.keys(json.steps['0']).length > 8
            },
            1: {
              ...json.steps['1'],
              complete: this.isComplete(json.steps['1'])
            }
          },
          ...this.selectedItem,
          estado_ev: 'Borrador',
          document_id: this.selectedItem?.key
        }
        console.log(payload)
        payload.estado_ev = payload.steps['1']?.complete && payload.steps['0']?.complete && !borradorBtn ? 'Gestionado' : 'Borrador'
        if (!payload.document_id) {
          return;
        }
          this.loading = true;
          //         const { id, json } = this.getLocal();
          await this.$api.updateAnualEVReport(payload, payload.document_id);
          this.loading = false;
      },
      async saveDraftBtn() {
          const { json } = this.getLocal();
          if (!!json.document_id && json.steps[0] && Object.keys(json.steps[0]).length > 1) {
              await this.saveDraft(json, true);
              this.$notification.success({
                  message: "Notificación",
                  description: "Guardado como borrador de forma exitosa",
              });
          } else {
              this.$notification.error({
                  message: "Notificación",
                  description: "No se pudo guardar borrador",
              });
          }
      },
      onChangeDateRadio(value) {
        this.filterDateOption = value
      },
      onChangeDateRange(value) {
        this.filterModel.start_date = value[0]
        this.filterModel.end_date = value[1]
      },
      setFiles(data) {
        this.files = data.files || []
      },
      isComplete(data) {
        const { requerimientos_humanos_tecnicos: rb1, suministro_solicitado_dcf: rb2} = data
        if (rb1 === "No" && rb2 === "No") {
          return !!data.justificacion_negativa
        } else if (rb1 === "Si" && rb2 === "No") {
          return !!data.descripcion_solicitud_dcf && !!data.fecha_requerimiento
        } else if (rb1 === "No" && rb2 === "Si") {
          return !!data.ev_atiende_requerimiento && !!data.como_atiende
        } else if (rb1 === "Si" && rb2 === "Si") {
          return (
              !!data.descripcion_solicitud_dcf &&
              !!data.fecha_requerimiento &&
              !!data.ev_atiende_requerimiento &&
              !!data.como_atiende
          )
        } else {
          return false
        }
      },
      jsonToFormData(val, formData = new FormData(), namespace = "") {
        if (typeof val !== "undefined" && val !== null) {
          if (Array.isArray(val)) {
            let i = 0;
            for (const element of val) {
              this.jsonToFormData(
                  element,
                  formData,
                  namespace + "[" + i + "]"
              );
              i++;
            }
          } else if (typeof val === "object" && !(val instanceof File)) {
            for (const propertyName in val) {
              if (Object.prototype.hasOwnProperty.call(val, propertyName)) {
                this.jsonToFormData(
                    val[propertyName],
                    formData,
                    namespace ? namespace + "[" + propertyName + "]" : propertyName
                );
              }
            }
          } else if (val instanceof Boolean) {
            formData.append(namespace, String(Number(val)));
          } else {
            formData.append(namespace, val);
          }
        }
        return formData;
      }
    },
  beforeDestroy() {
      this.refreshLocal()
  }
};
</script>
  
<style scoped>
button {
    border-radius: 5px;
}

.flex-wrap {
    flex-wrap: wrap;
}

.p-10 {
    padding: 10px;
}

.d-flex {
    display: flex;
}

.flex-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.ms-auto {
    margin-left: auto;
}

.btn-large {
    min-width: 300px;
}

.btn-small {
    min-width: 90px;
    padding: 3px;
}

.btn-white {
    box-shadow: 0 0 2mm #e7e7e7;
}

.btn-square {
    width: 40px;
    height: 40px;
}

.btn-square-small {
    width: 26px;
    height: 26px;
}

.btn-gray {
    background: #e7e7e7;
    color: black;
}

.btn-primary {
    background: #004884;
    color: white;
}

.btn-warning {
    background: #f8b446;
    color: white;
}

.btn-danger {
    background: #ea4a76;
    color: white;
}

.btn-success {
    background: #309d7e;
    color: white;
}

.btn-warning-light {
    background: #fff3e0;
    color: #f8b446;
}

.btn-danger-light {
    background: #ffedf2;
    color: #ea4a76;
}

.btn-primary-light {
    background: #edf3ff;
    color: #004884;
}

.btn-success-light {
    background: #e5fbf5;
    color: #309d7e;
}

.no-border {
    border: 0px !important;
}

.border-bottom-gray {
    border-bottom: 1px solid #e7e7e7;
}

.flag {
    min-width: 90px;
    display: flex;
    padding: 5px;
    border-radius: 5px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.search>input {
    border: 1px solid;
    border-radius: 10px;
    min-width: 400px;
    margin-right: 10px;
    padding: 0px 10px;
}

.search>button {
    border-radius: 10px;
}

.table {
    padding: 10px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    flex-wrap: wrap;
}

.tableHeader {
    font-weight: bold;
}

.table>div {
    flex-wrap: wrap;
}

.table>div>div {
    border-bottom: 1px solid black;
    padding: 10px 1px;
}

.progressbar {
    height: 26px;
    border-radius: 5px;
    background: #e5fbf5;
    display: flex;
    justify-content: start;
    align-content: center;
    align-items: center;
}

.progressbar>div {
    height: 26px;
    color: white;
    border-radius: 5px;
    background: #309d7e;
    width: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.w-100 {
    width: 100%;
}

.w-90 {
    width: 90%;
}

.w-80 {
    width: 80%;
}

.w-70 {
    width: 70%;
}

.w-60 {
    width: 60%;
}

.w-50 {
    width: 50%;
}

.w-45 {
    width: 45%;
}

.w-40 {
    width: 40%;
}

.w-35 {
    width: 35%;
}

.w-30 {
    width: 30%;
}

.w-25 {
    width: 25%;
}

.w-20 {
    width: 20%;
}

.w-15 {
    width: 15%;
}

.w-10 {
    width: 10%;
}

.w-5 {
    width: 5%;
}
</style>
  