<template>
  <div>

    <DynamicForm v-if="view !== 0" :hideTitle="disabledBtn" :urlform="'annual-report'" :step="view" :disableBtn="disabledBtn"
      @methodCancelDetail="cancel" :initForm="data['steps'][view]" :isReport="false" />

    <div class="container items-start" v-if="view === 0">
      <div v-if="disabledBtn" class="w-full flex flex-wrap place-content-around">

        <div class="w-1/4 mt-5 text-left flex flex-col align-middle">
          <label class="mb-2 text-xs md:text-xs text-gray-600 text-opacity-80"><b>Fecha del reporte</b></label>
          <div class=" h-11 rounded text-center  flex items-center justify-center">
            <p class="font-bold text-xl text-blue-600">{{ data.fecha_reporte }}</p>
          </div>
        </div>

        <div class="w-1/4 mt-5 text-left flex flex-col ">
          <label class="mb-2 text-xs md:text-xs text-gray-600 text-opacity-80"><b>Estado del informe</b></label>
          <div
              class=" h-11 rounded text-center  flex items-center justify-center"
              :class="data.estado === 'Borrador' ? 'text-blue-600 bg-blue-200' : data.estado === 'Por Gestionar' ? 'text-red-600 bg-red-100' : 'text-green-600 bg-green-100'"
          >
            <p class="w-full rounded-full text-center">
              {{ data.estado }}
            </p>
          </div>
        </div>


      </div>
      <DynamicForm :hideTitle="disabledBtn" :urlform="'annual-report'" :disableBtn="disabledBtn" :initForm="data['steps'][0]" :isReport="false" />

      <button v-if="disabledBtn" class="btn btn-primary-outline ms-auto me-1 mt-10" v-on:click="cancel">
        VOLVER
      </button>
      <button v-if="disabledBtn" class="btn btn-primary ms-1 me-auto" v-on:click="changeView(1)">
        SIGUIENTE
      </button>
    </div>

  </div>
</template>

<style scoped>
.container-dynamic-form {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
}

.container {
  padding: 30px;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
}
</style>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import DynamicForm from "@/components/DynamicForm/";

export default {
  props: {
    getDetail: Object,
    disabledBtn: {
      type: Boolean,
      default: true
    },
  },
  components: {
    Accordion,
    DynamicForm
  },
  data() {
    return {
      view: 0,
      data: {},
    }
  },
  created() {
    this.data = this.getDetail;
  },
  methods: {
    getLocalData() {
      const savedData = localStorage.getItem("DynamicForm");

      if (savedData) {
        try {
          const parsedData = JSON.parse(savedData);

          if (typeof parsedData === "object") {

            this.data = parsedData;
          }
        } catch (error) {

        }
      }
    },
    changeView(view = 0) {
      this.view = view;
    },
    cancel() {
      if (this.view == 0) {
        this.$emit("methodCancel", 1);
      } else {
        this.changeView(0)
      }
    },
  },
};
</script>